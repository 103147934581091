import { modulesType, permissionType } from "../../context/types";
import { useAuth } from "./useAuth";
export const useHasPermission = (
  // module: modulesType | modulesType[],
  // permission: permissionType | permissionType[] | "" =  ""
) => {
  const { permissions: authPermissions, userId } = useAuth();
  // if(Array.isArray(module) || Array.isArray(permission)){
  //   const permission_string = [];
  //   if(Array.isArray(module) && Array.isArray(permission))
  //   {
  //     module.forEach(m => {
  //       permission.forEach(p => {
  //         permission_string.push(`${m}:${p}`);
  //       });
  //     });
  //   }else if(Array.isArray(module))
  //   {
  //     module.forEach(m => {
  //       permission_string.push(`${m}${permission ? ':' : ''}${permission}`);
  //     });
  //   }
  //   else if(Array.isArray(permission)){
  //     permission.forEach(p => {
  //       permission_string.push(`${module}:${p}`);
  //     });
  //   }
  //   return permission_string.some(p => {
  //     return authPermissions.includes(p);
  //   })
  // }
  // else{
  //   const permission_string = `${module}${permission ? ":" : ""}${permission}`;
  //   console.log('permission_string ',permission_string);
  //   const a = authPermissions.includes(permission_string);
  //   console.log("aaaa ",a);
  //   // console.log('authPermissions ',authPermissions);


  //   return authPermissions.some(el=> el===permission_string);
  // }

  const checkUserPermission = (
    module: modulesType | modulesType[],
    permission: permissionType | permissionType[] | "" = "",
    option: 'AND' | '' = ''
  ) => {
    if (Array.isArray(module) || Array.isArray(permission)) {
      const permission_string = [];
      if (Array.isArray(module) && Array.isArray(permission)) {
        module.forEach(m => {
          permission.forEach(p => {
            permission_string.push(`${m}:${p}`);
          });
        });
      } else if (Array.isArray(module)) {
        module.forEach(m => {
          permission_string.push(`${m}${permission ? ':' : ''}${permission}`);
        });
      }
      else if (Array.isArray(permission)) {
        permission.forEach(p => {
          permission_string.push(`${module}:${p}`);
        });
      }
      if(option === 'AND'){
        return permission_string.every(p => {
          return authPermissions.includes(p);
        })
      }
      else {return permission_string.some(p => {
        return authPermissions.includes(p);
      })}
    }
    else {
      const permission_string = `${module}${permission ? ":" : ""}${permission}`;
      const a = authPermissions.includes(permission_string);

      if(option === 'AND'){
        return authPermissions.every(el => el === permission_string);
      }else {
        return authPermissions.some(el => el === permission_string);
      }
        
    }
  }

  const onAuthorized = (module: modulesType | modulesType[],
    permission: permissionType | permissionType[] | "" = "",option:"AND"|"" = ""): boolean => {
      return userId && checkUserPermission(module, permission,option);
  };

  const onAuthorizedRoutes = (module: modulesType | modulesType[],
    permission: permissionType | permissionType[] | "" = "",option:"AND"|"" = ""): boolean => {
      return userId && checkUserPermission(module, permission,option);
  };

  return {
    checkUserPermission,
    onAuthorized,
    onAuthorizedRoutes
  }
};

