import { Provider } from "react-redux";
import { makeStore } from "../config/store";
//pages
import { LiveCodingPage } from "../pages/live-coding";
import { VideoInterview } from "../pages/video-interview";
import OfflineCodingPage from "../pages/offline-coding";
import { PlaybackPage } from "../pages/playback";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EditorModals } from "../components/code-editor/modals/editor-modals";
import "../styles/main.scss";
import { permissionRoutesType, UnAuthorizePageComponent, useHasPermission, NotFoundPageComponent } from "@ucrecruits/globalstyle/src/ucrecruits-globalstyle";

const routes:Array<permissionRoutesType> =[
  {
    path:"/live-coding/interview/:room",
    element:<VideoInterview />,
    module:["recruiter","candidate"],
    permissions:""
  },
  {
    path:'/live-coding/online/:room',
    element:<LiveCodingPage />,
    module:["recruiter","candidate"],
    permissions:""
  },
  {
    path:'/live-coding/online',
    element:<LiveCodingPage />,
    module:["recruiter","candidate"],
    permissions:""
  },
  {
    path:'/live-coding/playback/:collectionId',
    element:<PlaybackPage />,
    module:["assessment"],
    permissions:"view"
  },
  {
    path:'/live-coding/offline/:jobId/:assessmentId',
    element:<OfflineCodingPage />,
    module:["candidate"],
    permissions:""
  },

]

export default function Root(props) {
  const {onAuthorizedRoutes} = useHasPermission()

  return (
    <Provider store={makeStore()}>
      <BrowserRouter>
        <Routes>
        {routes.map(route => {
          return <Route path={route.path} key={route.path} element={onAuthorizedRoutes(route.module, route.permissions) ? route.element : <UnAuthorizePageComponent/>} />
        })}
          <Route path={`/live-coding/*`} element={<NotFoundPageComponent/>} />

        </Routes>
      </BrowserRouter>
      <EditorModals />
    </Provider>
  );
}