export interface State {
  filteredData: any,
  formQuestions: boolean;
  assessmentDescription: string;
  assessmentName: string;
  isOnline: boolean;
  addTakeHomeTaskInput: any;
  questions: Questions[];
  deleteTakeHomeTask: any;
  deleteTestCase: any;
  deleteTestCaseInOut: any;
  counter: number;
  testCaseInputQuestion: any;
  testCases: any;
  testCaseOutputName: any;
  testCaseInputs: any;
  assessmentQuestion: Questions;
  validStatusBar: any;
  database: IDatabase;
  databases: IDatabase[];
}

export interface Questions {
  id: string;
  formStep: number;
  database: IDatabase | null;
  databases: IDatabase[] | null;
  nameQuestion: string;
  languages: any | null;
  packages: any | null;
  isIncludeTestCases: boolean;
  questionDescription: string;
  candidateInstruction: any;
  starterCode: string | null;
  outputType: string | null;
  outputDescription: string;
  testCaseInputs: TestCaseInputs[] | null;
  testCases: TestCases[] | null;
  validStatusBar: any;
}

export interface TestCaseInputs {
  id: number;
  name: string | null;
  type: string | null;
  description: string | null;
}

export interface TestCases {
  id: number;
  inputs: Inputs[];
  output: any | null;
}

export interface Inputs {
  index: number;
  name: any | null;
}

export interface IDatabase {
  id: number;
  title: string | null;
  packages: null;
  description: string | null;
  script: string | null;
  questionId: any;
}

export const Database = {
  id: 0,
  title: "",
  packages: null,
  description: "",
  script: "",
}

export const InputTestCase = {
  id: 0,
  name: "",
  type: "",
  description: "",
};

export const TestCasesInputName = {
  id: 0,
  name: "",
};

export const TaskTestCases = {
  id: 0,
  inputs: [
    {
      index: 0,
      name: "",
    },
  ],
  output: "",
};

export enum TestCaseStatus{
  PASSED = "Passed",
  FAILED = "Failed",
  RUNNING = "Running"
}

export enum DataTypes{
  STRING = "String",
  INTEGER = "Integer",
  ARRAY_INTEGER = "Inter Array",
  ARRAY_STRING = "String Array"
}
